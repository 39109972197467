import React, { useEffect, useContext } from "react"
// Material ui imports
import withStyles from "@material-ui/core/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Grow from "@material-ui/core/Grow"
import Img from "gatsby-image"
import MaterialCard from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import { deepOrange } from "@material-ui/core/colors"

//icons
import ChatIcon from "@mui/icons-material/Chat"
import BookIcon from "@mui/icons-material/Book"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BrushIcon from "@mui/icons-material/Brush"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt"
import PsychologyIcon from "@mui/icons-material/Psychology"

// Custom
import MenuContext from "../context/menu-context"
import CardBody from "../components/card/CardBody"

import Card from "../components/card/Card"
import CardHeader from "../components/card/CardHeader"

const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.grey['300'],
  //   overflow: 'hidden',
  //   // padding: 20,
  //   'min-height': '100vh', /* will cover the 100% of viewport */
  //   display: 'block',
  //   position: 'relative',
  //   // 'padding-bottom': '100px',
  // },
  grid: {
    width: "90%",
    marginTop: 10,
  },
  ul: {
    "list-style-type": "none",
    margin: 0,
    padding: 0,
  },
  progress: {
    margin: theme.spacing(2),
  },
  container: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "text-align": "center",
    "min-height": "50vh",
  },
  title: {
    padding: theme.spacing(2),
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "900",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  imageCard: {
    // maxWidth: 345,
    // width: "150vw",
    // width: "fit-content",
  },
  textStyle: {
    fontSize: "20px",
    fontWeight: "300",
    "text-align": "justify",
    display: "block",
    // paddingLeft: 10,
    paddingRight: 10,
  },
  alignVerticalHorizontal: {
    // '@media (max-width: 1280px)': {

    //   display: 'flex',
    //   'align-items': 'center',
    //   'justify-content': 'center',
    // },
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
  },
  imgWrap: {
    width: "160vw",
  },
  li: {
    color: deepOrange[700], // Texts
  },

  projectDetailsCard: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    // padding: "0 10",
    // margin: "0 10",
  },
  methodsDetailCard: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    padding: 10,
    fontSize: "13px",
    fontWeight: "600",
  },
})

const Projects = props => {
  // getFirestorePublications is from redux action connected so that it can dispatch
  const { classes, data } = props
  const { setMenu } = useContext(MenuContext)
  const [checked, setChecked] = React.useState(true) // for animation/transformation

  const currentPath = props.location.pathname

  // Run once after initial render
  useEffect(() => {
    setMenu(currentPath)
  }, [currentPath, setMenu])

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Grid container justify="center">
          <Grid
            spacing={4}
            alignItems="center"
            justify="center"
            container
            className={classes.grid}
          >
            {/* <Grid item xs={12}>
              <div className={classes.title}>
                <Typography variant="h6" gutterBottom>
                  Projects since 2019
                </Typography>
              </div>
            </Grid> */}
            <Grid item xs={12} lg={12}>
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 * (1 + 0.48) } : {})}
              >
                <Card className={classes.paper}>
                  <CardHeader color="primary">
                    <h2 className={classes.cardTitleWhite}>
                      SMARTWATCH GLANCEABLE VISUALIZATION FOR FAMILY
                      CO-REGULATION WITH ADHD CHILDREN - [Ongoing]
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={5}>
                        <MaterialCard>
                          <CardActionArea>
                            <div className={classes.alignVerticalHorizontal}>
                              <div className={classes.imgWrap}>
                                <Img
                                  fluid={data.cooltacoImg.childImageSharp.fluid}
                                  // fixed={data.voiceAssImg.childImageSharp.fixed}
                                  alt="Left: Sketch on paper of a flower with some petals and leafs collored. Right: an Apple Watch's home screen displaying a flower for each family member with colored petals."
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </CardActionArea>
                        </MaterialCard>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <Grid container>
                          <Grid item xs={12}>
                            <MaterialCard className={classes.methodsDetailCard}>
                              <h3> Methods</h3>
                              <Grid container>
                                <Grid item xs={2}>
                                  <div>
                                    <ChatIcon /> Interviews
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <PsychologyIcon /> Within-Subject Experiment
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>
                                    <ViewQuiltIcon />
                                    High-Fidelity Design
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <LocationOnIcon />
                                    Field deployment
                                  </div>
                                </Grid>
                              </Grid>
                            </MaterialCard>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.projectDetailsCard}
                          >
                            <div>
                              <ul className={classes.textStyle}>
                                <li>
                                  This is a project focused on helping ADHD
                                  children develop skills related to emotion
                                  self-regulation and goal setting through the
                                  collaboration with family members.
                                </li>
                                <li>
                                  This experiment and design study will involve
                                  the deployment of a smartwatch and a shared
                                  display in the home with visualizations
                                  depicting different family member's daily mood
                                  and personal goals.
                                </li>
                                <li>
                                  My objective is to investigate the impact to
                                  family collaboration and awareness from the
                                  use of the different devices. Additionally, it
                                  will explore the potential of watchfaces (the
                                  watch's home screen) for increased awareness
                                  between family members.
                                </li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grow>

              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 * (1 + 0.48) } : {})}
              >
                <Card className={classes.paper}>
                  <CardHeader color="primary">
                    <h2 className={classes.cardTitleWhite}>
                      SUPPORTING SELF-TRACKING THROUGH VOICE ASSISTANT
                      CONVERSATIONS - [COMPLETED]
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={5}>
                        <MaterialCard>
                          <CardActionArea>
                            <div className={classes.alignVerticalHorizontal}>
                              <div className={classes.imgWrap}>
                                <Img
                                  fluid={data.voiceAssImg.childImageSharp.fluid}
                                  // fixed={data.voiceAssImg.childImageSharp.fixed}
                                  alt="Voice assistant interactions for food journaling and co-design cards for voice conversations"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </CardActionArea>
                        </MaterialCard>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <Grid container>
                          <Grid item xs={12}>
                            <MaterialCard className={classes.methodsDetailCard}>
                              <h3> Methods</h3>
                              <Grid container>
                                <Grid item xs={2}>
                                  <div>
                                    <ChatIcon /> Interviews
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <BrushIcon /> Co-design
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>
                                    <ViewQuiltIcon />
                                    Low/Mid-Fidelity Design
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <LocationOnIcon />
                                    Field deployment
                                  </div>
                                </Grid>
                              </Grid>
                            </MaterialCard>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.projectDetailsCard}
                          >
                            <div>
                              <ul className={classes.textStyle}>
                                <li>
                                  I have conducted co-design activities to
                                  explore people's desired conversational
                                  interactions for food journaling and for
                                  recollection and reflection of eating
                                  behavior.
                                </li>
                                <li>
                                  Based on the co-design sessions, I will
                                  develop and deploy conversational journals
                                  with Alexa and Google assistant to explore
                                  people's perspectives on different
                                  conversational styles for food tracking.
                                </li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grow>

              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 * (2 + 0.48) } : {})}
              >
                <Card className={classes.paper}>
                  <CardHeader color="primary">
                    <h2 className={classes.cardTitleWhite}>
                      SUPPORTING CHILDREN WITH ADHD - [COMPLETED]
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={5}>
                        <MaterialCard>
                          <CardActionArea>
                            <div className={classes.alignVerticalHorizontal}>
                              <div className={classes.imgWrap}>
                                <Img
                                  fluid={
                                    data.coolcraigImg.childImageSharp.fluid
                                  }
                                  // fixed={data.coolcraigImg.childImageSharp.fixed}
                                  alt="Multiple devices"
                                />
                              </div>
                            </div>
                          </CardActionArea>
                        </MaterialCard>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <Grid container>
                          <Grid item xs={12}>
                            <MaterialCard className={classes.methodsDetailCard}>
                              <h3> Methods</h3>
                              <Grid container>
                                <Grid item xs={2}>
                                  <div>
                                    <ChatIcon /> Interviews
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>
                                    <ViewQuiltIcon />
                                    High/Low-Fidelity Design
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <LocationOnIcon />
                                    Field deployment
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <BrushIcon /> Co-design
                                  </div>
                                </Grid>
                              </Grid>
                            </MaterialCard>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.projectDetailsCard}
                          >
                            <div>
                              <ul className={classes.textStyle}>
                                <li>
                                  Families used an Apple Watch and phone app
                                  that promotes children’s self-regulation and
                                  self-efficacy with the collaboration of their
                                  parents.
                                </li>
                                <li>
                                  I interviewed families about their experiences
                                  with the apps and ideal technology support for
                                  managing ADHD, as well as app and wearable log
                                  analysis.
                                </li>
                                <li>
                                  I am leading co-design activities with parents
                                  and children on co-visualizing wearable
                                  tracking data, mood, responsibilities/to-dos,
                                  and others, for family collaboration around
                                  wellbeing and self-regulation.
                                </li>
                              </ul>
                              {/* <div className={classes.textStyle}>
                                <p>
                                  Families used an Apple Watch and phone app
                                  that promotes children’s self-regulation and
                                  self-efficacy with the collaboration of their
                                  parents.
                                </p>
                                <p>
                                  I interviewed families about their experiences
                                  with the apps and ideal technology support for
                                  managing ADHD, as well as app and wearable log
                                  analysis.
                                </p>
                                <p>
                                  I am leading co-design activities with parents
                                  and children on co-visualizing wearable
                                  tracking data, mood, responsibilities/to-dos,
                                  and others, for family collaboration around
                                  wellbeing and self-regulation.
                                </p>
                              </div> */}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grow>

              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 * (3 + 0.48) } : {})}
              >
                <Card className={classes.paper}>
                  <CardHeader color="primary">
                    <h2 className={classes.cardTitleWhite}>
                      MULTIMODAL FOOD TRACKING - [COMPLETED]
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={5}>
                        <MaterialCard className={classes.imageCard}>
                          <CardActionArea>
                            <div className={classes.alignVerticalHorizontal}>
                              <div className={classes.imgWrap}>
                                <Img
                                  fluid={
                                    data.multimodalImg.childImageSharp.fluid
                                  }
                                  // fixed={data.multimodalImg.childImageSharp.fixed}
                                  alt="Multiple devices"
                                />
                              </div>
                            </div>
                          </CardActionArea>
                        </MaterialCard>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.projectDetailsCard}
                          >
                            <MaterialCard className={classes.methodsDetailCard}>
                              <h3>Methods</h3>
                              <Grid container>
                                <Grid item xs={2}>
                                  <div>
                                    <ChatIcon /> Interviews
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div>
                                    <ViewQuiltIcon />
                                    High/Low-Fidelity Design
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <LocationOnIcon />
                                    Field deployment
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div>
                                    <AssignmentIcon /> Survey
                                  </div>
                                </Grid>
                              </Grid>
                            </MaterialCard>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={classes.projectDetailsCard}
                          >
                            <div>
                              <ul className={classes.textStyle}>
                                <li>
                                  We explored opportunities for leveraging
                                  multimodal and multiple devices for food
                                  tracking.
                                </li>
                                <li>
                                  This was achieved through the deployment of a
                                  prototype for Alexa, Google Assistant,
                                  smartphones, computer, and Apple Watch.
                                </li>
                                <li>
                                  We analyzed logs and interviews to investigate
                                  people's preferred food description practices,
                                  and opportunities for multimodality and
                                  multiple devices in food journaling.
                                </li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grow>

              {/* 
            </Grid>

            <Grid item xs={12} lg={6}> */}

              {/* <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 * (4 + 0.48) } : {})}
              >
                <Card className={classes.paper}>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>
                      GESTCOM - [COMPLETED]
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={5}>
                        <div className={classes.alignVerticalHorizontal}>
                          <MaterialCard className={classes.imageCard}>
                            <CardActionArea>
                              <Img
                                fixed={data.gestcomImg.childImageSharp.fixed}
                                alt="Gestcom project logo"
                              />
                            </CardActionArea>
                          </MaterialCard>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <ul className={classes.textStyle}>
                          <li>
                            Institutions frequently need to assess what are
                            necessary skills for employee activities and plan
                            appropriate training.
                          </li>
                          <li>
                            This project is focused on creating software tools
                            that promote such assessments and plannings.
                          </li>
                          <li>
                            Gestcom supported identification of skill gaps and
                            the creation of training programs that are aligned
                            with each employee's necessities.
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grow> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(Projects)

export const query = graphql`
  query {
    gestcomImg: file(relativePath: { eq: "gestcom2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    multimodalImg: file(relativePath: { eq: "modals.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coolcraigImg: file(relativePath: { eq: "coolcraig.png" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cooltacoImg: file(relativePath: { eq: "cooltaco.png" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    voiceAssImg: file(relativePath: { eq: "alexa_food_journal.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

// query {
//   gestcomImg: file(relativePath: { eq: "gestcom2.png" }) {
//     childImageSharp {
//       fixed(width: 172, height: 172, quality: 100) {
//         ...GatsbyImageSharpFixed_withWebp_noBase64
//       }
//     }
//   }
//   multimodalImg: file(relativePath: { eq: "modals.png" }) {
//     childImageSharp {
//       fixed(height: 500, width: 450, quality: 100) {
//         ...GatsbyImageSharpFixed_withWebp_noBase64
//       }
//     }
//   }
//   coolcraigImg: file(relativePath: { eq: "coolcraig.png" }) {
//     childImageSharp {
//       fixed(height: 300, width: 520, quality: 100) {
//         ...GatsbyImageSharpFixed_withWebp_noBase64
//       }
//     }
//   }
//   voiceAssImg: file(relativePath: { eq: "alexa_food_journal.png" }) {
//     childImageSharp {
//       fluid(maxWidth: 1000, quality: 100) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }

// export const query = graphql`
// query {
//   gestcomImg: file(relativePath: { eq: "gestcom2.png" }) {
//     childImageSharp {
//       fixed(width: 172, height: 172, quality: 100) {
//         ...GatsbyImageSharpFixed_withWebp_noBase64
//       }
//     }
//   },
//   multimodalImg: file(relativePath: { eq: "modals.png" }) {
//     childImageSharp {
//       fluid(maxWidth: 700) {
//         ...GatsbyImageSharpFluid_noBase64
//       }
//     }
//   }
// }
// `;
